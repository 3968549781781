<template>
  <vx-card>
    <form-wizard
        color="rgba(var(--vs-primary), 1)"
        :title="title"
        :subtitle="subTitle"
        :hide-buttons="true"
    >
      <div class="vx-row">
        <vs-divider class="vx-col w-full mt-5" position="left" color="dark">Información de notificación de
          {{ module }}
        </vs-divider>
        <div class="vx-col md:w-1/2 w-full ">
          <label for="recipient" class="w-full select-large">Destinatario</label>
          <vs-input
              id="recipient"
              name="recipient"
              v-model="notificationSettingsModel.recipient"
              v-validate="'required|email'"
              class="w-full"
              autocomplete="off"
          />
          <span class="text-danger">{{ errors.first("recipient") }}</span>
          <br/>
        </div>
      </div>
      <div class="vx-row">
        <vs-divider class="vx-col w-full mt-5" position="left" color="dark">Sección de filtros módulo</vs-divider>
        <div class="vx-col md:w-1/2 w-full">
          <div class="vx-row">
            <div class="vx-col md:w-2/2 w-full mt-2">
              <label for="recipient" class="w-full select-large">Categorías</label>
              <v-select
                  multiple
                  v-model="notificationSettingsModel.notifications_rules.entry.category_id"
                  :options="categories"
                  :reduce="item => item.code"
                  label="name"
                  @input="getEntries()"
              />
            </div>
          </div>
        </div>
        <div class="vx-col md:w-1/2 w-full">
          <div class="vx-row">
            <div class="vx-col md:w-2/2 w-full mt-2">
              <label for="recipient" class="w-full select-large">Paginas</label>
              <v-select
                  multiple
                  v-model="notificationSettingsModel.notifications_rules.entry.id"
                  :options="entries"
                  :reduce="item => item.id"
                  label="name"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col w-full mt-5">
          <div class="flex flex-wrap justify-between">
            <vs-row vs-type="flex" vs-justify="flex-end">
              <vs-button color="danger" type="border" class="mb-2" @click="goToBack">Cancelar</vs-button>
              <vs-button class="ml-3 mb-2" @click="createOrUpdateEntry" :disabled="!validateForm">Guardar</vs-button>
            </vs-row>
          </div>
        </div>
      </div>
    </form-wizard>
  </vx-card>
</template>

<script>
import NotificationSettingsClient from "../../utils/notificationSettingsClient";
import Notifier from "./../../utils/notification";
import EntriesClient from "../../utils/entriesClient";
import {FormWizard, TabContent} from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import "flatpickr/dist/flatpickr.css";
import vSelect from "vue-select";
import {Validator} from "vee-validate";

let notifier = new Notifier();
const NOTIFICATION_SETTINGS_CLIENT = new NotificationSettingsClient()
const ENTRIES_CLIENT = new EntriesClient("static-pages");

const requiredFields = ["recipient"];

let dict = requiredFields.reduce(
    (previousValue, currentValue) => {
      previousValue[currentValue] = {
        required: "* Este campo es requerido",
        email: "* Correo electrónico inválido"
      };
      return previousValue
    },
    {}
);

Validator.localize("es", {custom: dict});

export default {
  data() {
    return {
      entryTypeList: {
        "assets": {
          "name": "Activos Eventuales",
          "entry_type": "assets"
        },
        "static-pages": {
          "name": "Páginas estáticas",
          "entry_type": "static-pages"
        },
        "jobs": {
          "name": "Plazas Disponibles",
          "entry_type": "jobs"
        },
        "promotions": {
          "name": "Promociones",
          "entry_type": "promotions"
        },
        "products": {
          "name": "Productos",
          "entry_type": "products"
        },
        "projects": {
          "name": "Proyectos Inmobiliarios",
          "entry_type": "projects"
        }
      },
      queryStrings: {
        category_id:[],
      },
      title: "",
      subTitle: "",
      module: "",
      entry_type: "",
      entries: [],
      categories: [
        {
          name: "Landings",
          code: "landings",
        },
        {
          name: "Artículos Promocionales",
          code: "promotional_items"
        },
        {
          name: "Reclamos o Consultas",
          code: "complaints_or_queries"
        },
      ],
      notificationSettingsModel: {
        recipient: "",
        notifications_rules: {
          entry: {
            category_id: null,
            id: null
          },
          form_data: {},
        }
      },
    };
  },

  mounted() {
    if (this.$route.params.id !== undefined) {
      this.getPageTitle(true);
      this.loadNotificationSettingData(this.$route.params.id)
    } else {
      this.$vs.loading();
      this.getPageTitle(false);
      this.getEntries(false)
          .then( ()=>{
            this.$vs.loading.close();
          })
    }
  },

  computed: {
    validateForm() {
      return !this.errors.any()
          && requiredFields.reduce((accumulator, currentValue) => {
            return accumulator && this.notificationSettingsModel[currentValue] !== null;
          });
    }
  },

  methods: {
    getPageTitle(update) {
      let entry_type = "static-pages"
      let val = this.entryTypeList[entry_type]
      this.title = update ? `Editar destinatario de ${val.name}` : `Crear destinatario de ${val.name}`
      this.subTitle = "Configurar destinatario y los criterios para recibir notificaciones."
      this.module = val.name
      this.entry_type = val.entry_type
    },

    async getEntries(showLoading = true) {
      if (showLoading) this.$vs.loading();

      let category = this.notificationSettingsModel.notifications_rules.entry.category_id
      this.queryStrings.category_id =  Array.isArray(category) ? category : this.categories.map(item => item.code)

      await ENTRIES_CLIENT.all({queryStrings: this.queryStrings})
          .then(response => {
            this.cleanArrayEntries(
                this.notificationSettingsModel.notifications_rules.entry.id,
                response.data,
                this.queryStrings
            )
            this.entries = response.data;

            if (showLoading) this.$vs.loading.close();
          })
          .catch(error => {
            if (showLoading) this.$vs.loading.close();
            notifier.notification("error");
          });
    },

    async loadNotificationSettingData(id) {
      await NOTIFICATION_SETTINGS_CLIENT.retrieve(this.entry_type, id)
          .then(response => {
            this.getEntries(true)
                .then(()=>{
                  Object.assign(this.notificationSettingsModel, response.data);
                })
          })
          .catch(error => {
            notifier.notification("error");
          });
    },

    async createOrUpdateEntry() {
      let status_validation = true
      await this.$validator.validateAll().then(el => {
        status_validation = el
      })

      if(!status_validation) return false

      if (this.notificationSettingsModel.id !== null && this.notificationSettingsModel.id !== undefined && this.notificationSettingsModel.id !== "") {
        this.$vs.loading();
        this.cleanModel();

        await NOTIFICATION_SETTINGS_CLIENT.update(
            this.notificationSettingsModel, this.entry_type, this.$route.params.id
        )
            .then(response => {
              this.$vs.loading.close();
              notifier.notification("updated");
              this.goToBack();
            })
            .catch(error => {
              this.errors = error.response.data.errors;
              this.$vs.loading.close();
              notifier.alertMessage("error");
            });
      } else {
        this.$vs.loading();
        this.cleanModel();

        await NOTIFICATION_SETTINGS_CLIENT.create(this.notificationSettingsModel, this.entry_type)
            .then(response => {
              this.$vs.loading.close();
              notifier.notification("created");
              this.goToBack();
            })
            .catch(error => {
              if(error.response.status === 403 &&  error.response.data.errors.recipient !== undefined){
                this.errors.add({
                  field: 'recipient',
                  msg: `Ya existe un registro con el correo ${this.notificationSettingsModel.recipient} para ${this.module}`
                })
              }
              this.$vs.loading.close();
              notifier.alertMessage("error");
            });
      }
    },

    goToBack() {
      this.$router.push({name: "notification-settings", params: {entry_type: this.entry_type}});
    },

    cleanModel() {
      let exclude = ["entry_type", "id", "unix_created_at", "unix_updated_at"]

      Object.keys(this.notificationSettingsModel).forEach(key => {
        if (exclude.includes(key)) {
          delete this.notificationSettingsModel[key];
        }
      })

      this.notificationSettingsModel.notifications_rules.entry = this.cleanNullKeys(
          this.notificationSettingsModel.notifications_rules.entry
      )
    },

    cleanNullKeys(data){
      let newObject = {}

      for( let key in data ){
        if( Array.isArray(data[key]) && data[key].length > 0){
          newObject[key] = data[key]
        }
      }

      return newObject;
    },

    cleanArray(selected_items, response) {
      if (Array.isArray(selected_items)) {
        return selected_items.filter(el => { if (response.includes(el)) return el} )
      }

      return null
    },

    cleanArrayEntries(selected_items, response, queryString){
      if(Array.isArray(selected_items)){
        let array_selected = response.filter( el => { if(selected_items.includes(el.id))return el })

        array_selected = array_selected.map( el => {
          if(queryString.category_id.includes(el.category_id)){
            return el.id
          }
        })

        if(array_selected.includes(undefined)){
          this.notificationSettingsModel.notifications_rules.entry.id = null
          return false
        }
        this.notificationSettingsModel.notifications_rules.entry.id = array_selected
      }
    }
  },

  components: {
    FormWizard,
    TabContent,
    "v-select": vSelect,
  }
};

</script>